:root{
    --white: #fff;
    --black: #000;
    --color-primary: #248CCB;
    --color-secondary: #ED8021;
    --color-destructive: #d53e3e;

    --main-blue:#0b3155;
    --main-orange:#ef881f;
    --gray-light:#cdcdcd;
}
